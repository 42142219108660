import React from "react";
import Link from "next/link";

function CmsButton({ component, page_id='' }) {
  if (component.options.url != "") {
    return (
      <div className={`link`} id={`${component.id ?? ""}`}>
        <Link href={component.options.url}>
          <a target={component.options.url.startsWith('/') ? '_self' : '_blank' } className="externalBtn">
            <span>
              {component.options.link_text != ""
                ? component.options.link_text
                : component.options.url}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m31.71 15.29-10-10-1.42 1.42 8.3 8.29H0v2h28.59l-8.29 8.29 1.41 1.41 10-10a1 1 0 0 0 0-1.41z" data-name="3-Arrow Right"/></svg>
          </a>
        </Link>
      </div>
    );
  }
}

export default CmsButton;
